.preheader {
  background: #ed9121;
  color: #ffffff;
  border-top: 2px solid #630; }
  @media screen and (max-width: 800px) {
    .preheader__item--navigate {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      width: 100%; } }
  @media screen and (max-width: 1000px) {
    .preheader__item--feedback {
      display: none; } }
