.header {
  padding-top: 30px;
  padding-bottom: 15px;
  background: #ffffff; }
  .header__item {
    margin-bottom: 15px; }
    @media screen and (max-width: 1000px) {
      .header__item--address {
        display: none; } }
    @media screen and (max-width: 600px) {
      .header__item--phones {
        display: none; } }
