.logo {
  user-select: none;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center; }
  .logo__link {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    text-decoration: none; }
  .logo__img {
    width: 70px;
    display: block; }
  .logo__textcase {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: space-between;
    padding-left: 15px; }
  .logo__name {
    font-size: 30px;
    line-height: 35px;
    font-weight: bold;
    text-transform: uppercase;
    color: #212121; }
  .logo__slogan {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: #666666; }
  .logo__text {
    margin-left: 25px;
    padding-left: 25px;
    border-left: 1px solid #999999;
    color: #666666;
    font-size: 16px;
    line-height: 16px; }
    @media screen and (max-width: 800px) {
      .logo__text {
        display: none; } }
