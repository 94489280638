.mainform {
  position: absolute;
  width: 100%;
  z-index: 100;
  transform: translateY(-50%); }
  .mainform__title {
    font-size: 40px;
    color: #ffbf00;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  .mainform__subtitle {
    font-weight: 400;
    color: #ffffff;
    font-size: 30px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  .mainform__wrapper {
    width: 100%;
    margin-top: 10px; }
  .mainform__form {
    background: #a65e2e;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding: 25px 30px;
    text-align: center;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 2px solid  #fff; }
    .mainform__form label {
      display: none; }
  .mainform__forminner {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #ffffff;
    text-align: center; }
  .mainform__item {
    width: 33.33333%;
    max-width: 350px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-top: 3px; }
    .mainform__item--hidden {
      display: none; }
  .mainform__input {
    width: 100%;
    font-family: 'futura';
    font-size: 20px;
    line-height: 45px;
    padding: 0 15px;
    color: #212121;
    box-sizing: border-box;
    text-align: center;
    background: #ffffff; }
  .mainform__btn {
    width: 100%;
    min-width: 100%;
    background: #ffbf00;
    text-transform: uppercase;
    color: #212121;
    font-weight: 600;
    padding: 0 20px;
    line-height: 45px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer; }
    .mainform__btn:hover {
      background: #ed9121;
      color: #212121; }
  .mainform__formtitle {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 12px;
    text-align: center;
    width: 100%; }
  .mainform__aferta {
    font-size: 14px;
    color: #ffffff;
    margin-top: 5px;
    margin-bottom: 2px;
    text-align: center;
    width: 100%; }
