.menuicon {
  display: none;
  cursor: pointer;
  line-height: 55px;
  font-size: 25px; }
  @media screen and (max-width: 800px) {
    .menuicon {
      display: block;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center; } }
  .menuicon__text {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 10px; }
