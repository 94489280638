.consultation {
  background: #ffbf00;
  padding-top: 40px;
  padding-bottom: 20px; }
  @media screen and (max-width: 800px) {
    .consultation {
      padding-bottom: 25px;
      padding-top: 30px; } }
  .consultation__item {
    margin-bottom: 20px;
    padding: 0 20px;
    box-sizing: border-box; }
    @media screen and (max-width: 800px) {
      .consultation__item {
        margin-bottom: 10px; } }
  .consultation__title {
    font-weight: 700;
    font-size: 26px;
    text-transform: uppercase;
    white-space: nowrap; }
    @media screen and (max-width: 800px) {
      .consultation__title {
        font-size: 20px; } }
  .consultation__text {
    font-size: 18px;
    text-align: center; }
    @media screen and (max-width: 800px) {
      .consultation__text {
        font-size: 14px;
        margin-bottom: 5px; } }
