.section {
  padding-bottom: 30px; }
  .section__titlecase {
    margin: 50px 0; }
  .section__title {
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    font-size: 48px;
    color: #663300; }
    .section__title--contacts {
      font-size: 36px; }
      @media screen and (max-width: 1000px) {
        .section__title--contacts {
          font-size: 32px; } }
      @media screen and (max-width: 800px) {
        .section__title--contacts {
          font-size: 28px; } }
      @media screen and (max-width: 600px) {
        .section__title--contacts {
          font-size: 24px; } }
      @media screen and (max-width: 400px) {
        .section__title--contacts {
          font-size: 20px; } }
    @media screen and (max-width: 1000px) {
      .section__title {
        font-size: 44px; } }
    @media screen and (max-width: 800px) {
      .section__title {
        font-size: 40px; } }
    @media screen and (max-width: 600px) {
      .section__title {
        font-size: 36px; } }
    @media screen and (max-width: 400px) {
      .section__title {
        font-size: 32px; } }
  .section__subtitle {
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
    color: #212121;
    font-weight: 600; }
    @media screen and (max-width: 1000px) {
      .section__subtitle {
        font-size: 22px; } }
    @media screen and (max-width: 800px) {
      .section__subtitle {
        font-size: 20px; } }
    @media screen and (max-width: 600px) {
      .section__subtitle {
        font-size: 18px; } }
    @media screen and (max-width: 400px) {
      .section__subtitle {
        font-size: 16px; } }
    .section__subtitle--contacts {
      font-size: 18px; }
