.content {
  padding: 25px 0 50px 0; }
  .content--index {
    padding: 50px 0; }
  .content__item--left {
    width: 28%;
    padding-right: 25px;
    box-sizing: border-box; }
  .content__item--center {
    width: 72%; }
