.slider {
  border-bottom: 3px solid #663300; }
  .slider__list {
    position: relative;
    z-index: 35;
    border-bottom: 15px solid #ffbf00; }
  .slider__item {
    position: relative;
    border-bottom: 3px solid #663300;
    display: block; }
    .slider__item:before {
      content: '';
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      background: rgba(0, 0, 0, 0.5); }
    .slider__item--item1 {
      background: url("../img/slider_01.jpg") 50% 50%/cover no-repeat #cccccc; }
    .slider__item--item2 {
      background: url("../img/slider_02.jpg") 50% 50%/cover no-repeat #cccccc; }
    .slider__item--item3 {
      background: url("../img/slider_03.jpg") 50% 50%/cover no-repeat #cccccc; }
  .slider__link {
    text-decoration: none;
    display: block; }
  .slider__textcase {
    padding: 50px 0; }
    @media screen and (max-width: 1000px) {
      .slider__textcase {
        padding: 45px 0; } }
    @media screen and (max-width: 800px) {
      .slider__textcase {
        padding: 40px 0; } }
    @media screen and (max-width: 600px) {
      .slider__textcase {
        padding: 35px 0; } }
    @media screen and (max-width: 400px) {
      .slider__textcase {
        padding: 30px 0; } }
  .slider__title {
    color: #ffbf00;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 60px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 1000px) {
      .slider__title {
        font-size: 52px; } }
    @media screen and (max-width: 800px) {
      .slider__title {
        font-size: 44px; } }
    @media screen and (max-width: 600px) {
      .slider__title {
        font-size: 36px; } }
    @media screen and (max-width: 400px) {
      .slider__title {
        font-size: 28px; } }
  .slider__subtitle {
    color: #ffffff;
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 1000px) {
      .slider__subtitle {
        font-size: 34px; } }
    @media screen and (max-width: 800px) {
      .slider__subtitle {
        font-size: 28px; } }
    @media screen and (max-width: 600px) {
      .slider__subtitle {
        font-size: 22px; } }
    @media screen and (max-width: 400px) {
      .slider__subtitle {
        font-size: 16px; } }
