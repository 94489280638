@media screen and (max-width: 800px) {
  .yandexmap {
    display: none; } }

.yandexmap .ymaps-2-1-62-ground-pane {
  filter: grayscale(50%) sepia(50%); }

.yandexmap .ymaps-2-1-62-copyright__wrap {
  display: none;
  opacity: 0;
  visibility: hidden;
  z-index: -999;
  position: absolute; }
