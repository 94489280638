.wrapper {
  width: 95%;
  max-width: 1200px;
  margin: auto;
  position: relative;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  z-index: 10; }
  .wrapper--preheader {
    flex-direction: row;
    justify-content: space-between; }
  .wrapper--header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    @media screen and (max-width: 600px) {
      .wrapper--header {
        flex-direction: column;
        align-items: center;
        align-content: center;
        justify-content: center; } }
  .wrapper--slider {
    min-height: 350px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    z-index: 35; }
    @media screen and (max-width: 1000px) {
      .wrapper--slider {
        min-height: 310px; } }
    @media screen and (max-width: 800px) {
      .wrapper--slider {
        min-height: 270px; } }
    @media screen and (max-width: 600px) {
      .wrapper--slider {
        min-height: 230px; } }
    @media screen and (max-width: 400px) {
      .wrapper--slider {
        min-height: 190px; } }
  .wrapper--mainmenu {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
  .wrapper--content {
    flex-direction: column; }
  .wrapper--mainform, .wrapper--actionform, .wrapper--businessform {
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center; }
  .wrapper--consultation {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    @media screen and (max-width: 800px) {
      .wrapper--consultation {
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-content: center; } }
  .wrapper--footer {
    flex-direction: row;
    justify-content: space-between; }
    @media screen and (max-width: 600px) {
      .wrapper--footer {
        flex-direction: column;
        text-align: center; } }
