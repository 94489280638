.mainmenu {
  background: #ffbf00;
  color: #212121;
  position: relative;
  z-index: 145;
  border-bottom: 3px solid #663300; }
  @media screen and (max-width: 800px) {
    .mainmenu__item--mainmenu {
      width: 100%;
      width: auto; } }
  .mainmenu__item--search {
    width: 180px; }
    @media screen and (max-width: 600px) {
      .mainmenu__item--search {
        width: auto; } }
  .mainmenu__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px 0;
    user-select: none; }
    @media screen and (max-width: 800px) {
      .mainmenu__list {
        display: none;
        border-top: 1px solid rgba(0, 0, 0, 0.25); } }
    .mainmenu__list > .mainmenu__item {
      margin-right: 5px; }
      .mainmenu__list > .mainmenu__item > a {
        line-height: 55px;
        display: block;
        padding: 0 25px;
        font-weight: 600;
        text-decoration: none;
        color: #212121;
        font-size: 20px;
        box-sizing: border-box;
        text-transform: uppercase;
        position: relative; }
        .mainmenu__list > .mainmenu__item > a:hover {
          background: #a65e2e;
          color: #ffffff; }
      .mainmenu__list > .mainmenu__item--active > a {
        background: #a65e2e;
        color: #ffffff; }
        .mainmenu__list > .mainmenu__item--active > a:after {
          content: '';
          position: absolute;
          background: url("../img/icon_navigate_parent@2x.svg") 50% 50%/11px 11px no-repeat;
          right: 20px;
          height: 11px;
          width: 11px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          filter: invert(100%) grayscale(100%); }
    .mainmenu__list > .mainmenu__item--parent {
      position: relative; }
      .mainmenu__list > .mainmenu__item--parent > a {
        padding-right: 40px; }
      .mainmenu__list > .mainmenu__item--parent:after {
        content: '';
        position: absolute;
        background: url("../img/icon_navigate_parent@2x.svg") 50% 50%/11px 11px no-repeat;
        right: 20px;
        height: 11px;
        width: 11px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer; }
      .mainmenu__list > .mainmenu__item--parent:hover:after {
        filter: invert(100%) grayscale(100%); }
    .mainmenu__list > .mainmenu__item--parent:hover {
      background: #ed9121; }
      .mainmenu__list > .mainmenu__item--parent:hover > a {
        color: #ffffff; }
      .mainmenu__list > .mainmenu__item--parent:hover > .mainmenu__nav-child {
        display: block; }
  .mainmenu__nav-child {
    min-width: 100%;
    position: absolute;
    z-index: 50;
    display: none;
    box-sizing: border-box; }
    @media screen and (max-width: 800px) {
      .mainmenu__nav-child {
        position: relative;
        display: block; } }
    .mainmenu__nav-child > .mainmenu__item {
      width: 100%;
      border-top: 1px solid rgba(255, 255, 255, 0.25);
      box-sizing: border-box;
      background: #ed9121; }
      .mainmenu__nav-child > .mainmenu__item > a {
        width: 100%;
        line-height: 30px;
        display: block;
        padding: 0 20px;
        text-decoration: none;
        color: #212121;
        font-size: 16px;
        box-sizing: border-box;
        white-space: nowrap; }
        .mainmenu__nav-child > .mainmenu__item > a:hover {
          background: #a65e2e;
          color: #ffffff; }
      .mainmenu__nav-child > .mainmenu__item--active > a {
        background: #a65e2e;
        color: #ffffff; }
