.popup {
  display: none;
  text-align: center; }
  .popup__bg {
    background: rgba(100, 100, 100, 0.5);
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    width: 100%;
    height: 100%; }
  .popup__close {
    width: 40px;
    height: 40px;
    background: #ffbf00;
    line-height: 40px;
    font-weight: bold;
    text-align: center;
    position: absolute;
    right: -20px;
    top: -20px;
    text-transform: uppercase;
    font-size: 26px;
    cursor: pointer; }
  .popup__window {
    width: 90%;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1001;
    max-width: 500px;
    max-height: 400px;
    background: #ffffff; }
  .popup .actionform__form {
    margin-bottom: 0; }
    .popup .actionform__form .sf2-body {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      color: #ffffff; }
  .popup .actionform__item {
    width: 100%;
    max-width: none;
    padding: 0; }
