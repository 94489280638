.pricelist {
  width: 100%; }
  .pricelist__item {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    border: 2px solid #ffbf00;
    border-top: 0;
    padding: 20px;
    margin-bottom: 30px; }
    .pricelist__item table {
      width: 100%; }
      .pricelist__item table tbody {
        width: 100%; }
      .pricelist__item table tr {
        width: 100%; }
      .pricelist__item table tr:nth-child(odd) {
        background: #e2e2e2; }
      .pricelist__item table tr:nth-child(even) {
        background: #ffffff; }
      .pricelist__item table th,
      .pricelist__item table td {
        padding: 5px 10px;
        box-sizing: border-box;
        font-size: 16px;
        line-height: 20px;
        text-align: center; }
        @media screen and (max-width: 600px) {
          .pricelist__item table th,
          .pricelist__item table td {
            font-size: 14px;
            line-height: 16px;
            padding: 3px 5px;
            display: block;
            width: 100%;
            text-align: center !important; } }
      .pricelist__item table td:first-child {
        text-align: left; }
