.easytabs {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-content: flex-start;
  padding-top: 15px; }
  .easytabs .easytabs__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row; }
  .easytabs .easytabs__item {
    background: #ffbf00;
    border: 2px solid #ffbf00;
    border-bottom: 0;
    text-align: center; }
    .easytabs .easytabs__item.active {
      background: #ffffff; }
  .easytabs .easytabs__link {
    line-height: 45px;
    display: block;
    color: #212121;
    text-decoration: none;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap; }
    @media screen and (max-width: 1000px) {
      .easytabs .easytabs__link {
        font-size: 16px;
        line-height: 35px;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.25); } }
