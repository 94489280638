.breadcrumbs {
  margin-bottom: 25px;
  font-size: 16px; }
  .breadcrumbs__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .breadcrumbs__divider {
    padding-right: 5px; }
  .breadcrumbs__link {
    color: #663300;
    text-decoration: none; }
    .breadcrumbs__link:hover {
      text-decoration: underline; }
  .breadcrumbs__active {
    color: #787878; }
