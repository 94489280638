@charset "UTF-8";
.services {
  background: url("../img/advantages_bg.jpg") 50% 75%/auto auto repeat #e2e2e2; }
  .services__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 15px;
    background: #ffbf00;
    padding: 20px 10px;
    padding-bottom: 0px;
    margin-bottom: 40px; }
  .services__item {
    width: 33.33333333%; }
    @media screen and (max-width: 1000px) {
      .services__item {
        width: 50%; } }
    @media screen and (max-width: 600px) {
      .services__item {
        width: 100%; } }
    .services__item:nth-child(n+7) {
      display: none; }
  .services__link {
    display: block;
    padding: 20px;
    background: #ffffff;
    margin: 10px;
    margin-top: 0;
    margin-bottom: 20px;
    box-sizing: border-box;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    position: relative;
    transition: all .35s ease; }
    @media screen and (max-width: 600px) {
      .services__link {
        padding: 10px; } }
    .services__link:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0; }
    .services__link:after {
      content: 'Подробнее';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #a65e2e;
      color: #ffffff;
      line-height: 45px;
      padding: 0 20px;
      font-weight: 600;
      opacity: 0;
      text-transform: uppercase;
      font-size: 16px; }
    .services__link:hover {
      border-color: #a65e2e; }
    .services__link:hover:before, .services__link:hover:after {
      opacity: 1; }
  .services__imgcase {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    height: 150px;
    padding: 10px; }
    @media screen and (max-width: 600px) {
      .services__imgcase {
        height: 80px; } }
  .services__img {
    width: auto;
    max-height: 120px;
    display: block;
    margin: auto; }
    @media screen and (max-width: 600px) {
      .services__img {
        max-height: 60px; } }
  .services__textcase {
    display: block; }
  .services__title {
    font-weight: 600;
    text-align: center;
    color: #212121;
    font-size: 20px;
    text-transform: uppercase; }
    @media screen and (max-width: 600px) {
      .services__title {
        font-size: 16px; } }
  .services__price {
    color: #787878;
    font-size: 26px;
    font-weight: bold;
    text-align: center;
    margin-top: 5px; }
    .services__price span {
      color: red;
      font-size: 34px; }
      @media screen and (max-width: 600px) {
        .services__price span {
          font-size: 24px; } }
    @media screen and (max-width: 600px) {
      .services__price {
        font-size: 20px;
        margin-bottom: 10px; } }
    .services__price--small {
      font-size: 20px;
      margin-top: 0; }
      .services__price--small span {
        font-size: 24px; }
