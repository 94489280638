.businessform {
  background: url("../img/businessform_bg.jpg") 50% 75%/cover no-repeat #ed9121;
  position: relative;
  padding: 50px 0; }
  .businessform:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); }
  .businessform__title {
    font-size: 40px;
    color: #ffbf00;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  .businessform__subtitle {
    font-weight: 400;
    color: #ffffff;
    font-size: 30px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  .businessform__wrapper {
    width: 100%;
    margin-top: 30px; }
  .businessform__form {
    background: #a65e2e;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding: 25px;
    margin-bottom: 10px; }
    .businessform__form .sf2-body {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      color: #ffffff; }
    .businessform__form label {
      display: none; }
  .businessform__item {
    width: 33.33333%;
    max-width: 350px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-top: 3px; }
    .businessform__item--hidden {
      display: none; }
  .businessform__input {
    width: 100%;
    font-family: 'futura';
    font-size: 20px;
    line-height: 45px;
    padding: 0 15px;
    color: #212121;
    box-sizing: border-box;
    text-align: center;
    background: #ffffff; }
  .businessform__btn {
    width: 100%;
    text-transform: uppercase;
    color: #212121;
    font-weight: 600;
    padding: 0 20px;
    line-height: 45px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
    background: #ffbf00; }
    .businessform__btn:hover {
      background: #ed9121;
      color: #ffdab9; }
  .businessform__formtitle {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 12px; }
  .businessform__aferta {
    font-size: 14px;
    color: #ffffff;
    margin-top: 5px;
    margin-bottom: 2px; }
  .businessform__title {
    font-size: 40px;
    color: #ffbf00;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  .businessform__subtitle {
    font-weight: 400;
    color: #ffffff;
    font-size: 30px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
  .businessform__wrapper {
    width: 100%;
    margin-top: 10px; }
  .businessform__form {
    background: #a65e2e;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding: 25px 30px;
    text-align: center;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: 2px solid  #fff; }
    .businessform__form label {
      display: none; }
  .businessform__forminner {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #ffffff;
    text-align: center; }
  .businessform__item {
    width: 100%;
    padding: 10px;
    box-sizing: border-box; }
    .businessform__item--hidden {
      display: none; }
  .businessform__input {
    width: 100%;
    font-family: 'futura';
    font-size: 20px;
    line-height: 45px;
    padding: 0 15px;
    color: #212121;
    box-sizing: border-box;
    text-align: center;
    background: #ffffff; }
  .businessform__btn {
    width: 100%;
    min-width: 100%;
    background: #ffbf00;
    text-transform: uppercase;
    color: #212121;
    font-weight: 600;
    padding: 0 20px;
    line-height: 45px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer;
    margin-bottom: 10px; }
    .businessform__btn:hover {
      background: #ed9121;
      color: #212121; }
  .businessform__formtitle {
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 12px;
    text-align: center;
    width: 100%; }
  .businessform__aferta {
    font-size: 14px;
    color: #ffffff;
    margin-top: 5px;
    margin-bottom: 2px;
    text-align: center;
    width: 100%; }
