@charset "UTF-8";
.catother {
  background: #ffbf00;
  padding: 20px 15px;
  margin: 0 10px;
  margin-bottom: 30px;
  padding-bottom: 10px; }
  .catother__name {
    text-align: center;
    font-weight: 700;
    font-size: 26px;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: #630; }
  .catother__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
  .catother__item {
    width: calc(25% - 10px);
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    margin: 0 5px;
    box-sizing: border-box;
    margin-bottom: 10px; }
    @media screen and (max-width: 1000px) {
      .catother__item {
        width: calc(33.333333% - 10px); } }
    @media screen and (max-width: 800px) {
      .catother__item {
        width: calc(50% - 10px); } }
    @media screen and (max-width: 600px) {
      .catother__item {
        width: calc(100% - 10px); } }
  .catother__link {
    display: block;
    padding: 10px;
    text-decoration: none;
    position: relative;
    height: 100%; }
    .catother__link:before {
      content: 'Подробнее';
      position: absolute;
      background: #a65e2e;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: bold;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 101;
      font-size: 14px;
      line-height: 30px;
      padding: 0 15px;
      opacity: 0;
      transition: all .25s ease; }
    .catother__link:after {
      content: '';
      position: absolute;
      z-index: 100;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.5);
      opacity: 0;
      transition: all .25s ease; }
    .catother__link:hover:after, .catother__link:hover:before {
      opacity: 1; }
  .catother__title {
    display: block;
    text-align: center;
    font-weight: bold;
    color: #212121;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 20px; }
  .catother__imgcase {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 100%;
    padding-bottom: 10px; }
  .catother__img {
    display: block;
    width: auto;
    height: 100%;
    max-height: 100px; }
