.advantages {
  background: url("../img/advantages_bg.jpg") 50% 75%/auto auto repeat #e2e2e2; }
  .advantages__list {
    margin-bottom: 10px;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 20px; }
    @media screen and (max-width: 800px) {
      .advantages__list {
        justify-content: center;
        margin-top: 0; } }
  .advantages__item {
    width: 25%;
    margin-bottom: 30px;
    padding: 0 10px;
    box-sizing: border-box; }
    @media screen and (max-width: 1000px) {
      .advantages__item {
        margin-bottom: 20px; } }
    @media screen and (max-width: 800px) {
      .advantages__item {
        width: 33.33333%; } }
    @media screen and (max-width: 600px) {
      .advantages__item {
        width: 50%; } }
    @media screen and (max-width: 400px) {
      .advantages__item {
        width: 100%; } }
  .advantages__imgcase {
    background: #ed9121;
    width: 80px;
    height: 48px;
    margin: auto;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    justify-content: center;
    user-select: none;
    margin-top: 10px;
    margin-bottom: 30px;
    position: relative;
    z-index: 20; }
    @media screen and (max-width: 1000px) {
      .advantages__imgcase {
        width: 40px;
        height: 24px;
        margin-bottom: 10px; } }
    .advantages__imgcase:before {
      content: '';
      position: absolute;
      background: #ed9121;
      width: 80px;
      height: 48px;
      transform: rotate(60deg); }
      @media screen and (max-width: 1000px) {
        .advantages__imgcase:before {
          width: 40px;
          height: 24px; } }
    .advantages__imgcase:after {
      content: '';
      position: absolute;
      background: #ed9121;
      width: 80px;
      height: 48px;
      transform: rotate(-60deg); }
      @media screen and (max-width: 1000px) {
        .advantages__imgcase:after {
          width: 40px;
          height: 24px; } }
  .advantages__img {
    width: 40px;
    height: 40px;
    display: block;
    position: relative;
    z-index: 40; }
    @media screen and (max-width: 1000px) {
      .advantages__img {
        width: 20px;
        height: 20px; } }
  .advantages__textcase {
    padding: 10px 0; }
  .advantages__title {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 600;
    color: #434343; }
    @media screen and (max-width: 1000px) {
      .advantages__title {
        font-size: 12px; } }
