@charset "UTF-8";
.category__title {
  text-align: center;
  font-weight: bold;
  font-size: 34px;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 10px;
  color: #663300; }
  @media screen and (max-width: 1000px) {
    .category__title {
      font-size: 24px; } }
  @media screen and (max-width: 800px) {
    .category__title {
      font-size: 20px; } }

.category-child {
  padding-top: 15px;
  padding-bottom: 30px; }
  .category-child__list {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    background: #ffbf00 !important;
    padding: 20px 10px !important;
    padding-bottom: 0px !important; }
  .category-child__item {
    padding: 0 !important;
    margin: 0 !important;
    background: none !important;
    width: calc(33.3333333% - 20px) !important;
    box-sizing: border-box;
    background: #ffffff !important;
    margin: 0 10px !important;
    margin-bottom: 20px !important;
    box-sizing: border-box;
    padding: 15px 20px !important; }
    @media screen and (max-width: 1000px) {
      .category-child__item {
        width: calc(50% - 20px) !important; } }
    @media screen and (max-width: 600px) {
      .category-child__item {
        width: calc(100% - 20px) !important; } }
  .category-child__link {
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    color: #212121;
    text-transform: uppercase;
    padding-bottom: 10px;
    position: relative; }
    .category-child__link:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.5);
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      opacity: 0; }
    .category-child__link:after {
      content: 'Подробнее';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: #a65e2e;
      color: #ffffff;
      line-height: 45px;
      padding: 0 20px;
      font-weight: 600;
      opacity: 0;
      text-transform: uppercase;
      font-size: 16px; }
    .category-child__link:hover:before, .category-child__link:hover:after {
      opacity: 1; }
  .category-child__img {
    max-height: 120px;
    display: block;
    margin-bottom: 20px;
    margin-top: 10px; }
    @media screen and (max-width: 600px) {
      .category-child__img {
        max-height: 80px; } }
