.feedback {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  user-select: none; }
  .feedback__btn {
    background: #a65e2e;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    padding: 0 15px;
    line-height: 34px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    white-space: nowrap;
    margin-top: 3px; }
    .feedback__btn:hover {
      background: #663300;
      color: #ffffff; }
  .feedback__text {
    font-size: 14px;
    color: #999999; }
