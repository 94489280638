.article a {
  color: #663300; }

.article h1 {
  text-align: center;
  font-weight: bold;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 15px;
  margin-top: 10px;
  color: #663300; }
  @media screen and (max-width: 1000px) {
    .article h1 {
      font-size: 24px; } }
  @media screen and (max-width: 800px) {
    .article h1 {
      font-size: 20px; } }

.article h2 {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 5px;
  color: #663300; }
  @media screen and (max-width: 1000px) {
    .article h2 {
      font-size: 20px; } }
  @media screen and (max-width: 800px) {
    .article h2 {
      font-size: 16px; } }

.article h3 {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 5px;
  color: #663300; }
  @media screen and (max-width: 1000px) {
    .article h3 {
      font-size: 20px; } }
  @media screen and (max-width: 800px) {
    .article h3 {
      font-size: 16px; } }

.article h4 {
  text-align: center;
  font-weight: 600;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: 5px;
  color: #663300; }
  @media screen and (max-width: 1000px) {
    .article h4 {
      font-size: 20px; } }
  @media screen and (max-width: 800px) {
    .article h4 {
      font-size: 18px; } }

.article p {
  font-size: 18px;
  line-height: 28px;
  text-indent: 25px;
  padding-bottom: 15px;
  color: #434343; }
  @media screen and (max-width: 600px) {
    .article p {
      font-size: 16px;
      line-height: 20px; } }

.article ul {
  margin-bottom: 20px;
  margin-left: 25px;
  font-size: 12px; }
  .article ul li {
    padding-left: 25px;
    margin-bottom: 10px;
    font-size: 16px;
    line-height: 20px;
    color: #212121;
    background: url("../img/icon_component_square@2x.svg") 3px 5px/13px 13px no-repeat; }
