.actionform {
  background: url("../img/actionform_bg.jpg") 50% 75%/cover no-repeat #ed9121;
  position: relative;
  padding: 50px 0; }
  .actionform:before {
    content: '';
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5); }
  .actionform__forminner {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color: #ffffff;
    text-align: center; }
  .actionform__title {
    font-size: 40px;
    color: #ffbf00;
    text-transform: uppercase;
    font-weight: bold;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 1000px) {
      .actionform__title {
        font-size: 36px; } }
    @media screen and (max-width: 800px) {
      .actionform__title {
        font-size: 32px; } }
    @media screen and (max-width: 600px) {
      .actionform__title {
        font-size: 28px; } }
    @media screen and (max-width: 400px) {
      .actionform__title {
        font-size: 24px; } }
  .actionform__subtitle {
    font-weight: 400;
    color: #ffffff;
    font-size: 30px;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5); }
    @media screen and (max-width: 1000px) {
      .actionform__subtitle {
        font-size: 28px; } }
    @media screen and (max-width: 800px) {
      .actionform__subtitle {
        font-size: 26px; } }
    @media screen and (max-width: 600px) {
      .actionform__subtitle {
        font-size: 24px; } }
    @media screen and (max-width: 400px) {
      .actionform__subtitle {
        font-size: 22px; } }
  .actionform__wrapper {
    width: 100%;
    margin-top: 30px; }
  .actionform__form {
    background: #a65e2e;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.25);
    padding: 25px 30px;
    margin-bottom: 10px;
    border: 2px solid #ffffff;
    box-sizing: border-box; }
    .actionform__form .sf2-body {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      color: #ffffff; }
      @media screen and (max-width: 1000px) {
        .actionform__form .sf2-body {
          flex-direction: column; } }
    @media screen and (max-width: 800px) {
      .actionform__form {
        padding: 20px 10px; } }
    .actionform__form label {
      display: none; }
  .actionform__item {
    width: 33.33333%;
    max-width: 350px;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    margin-top: 3px; }
    .actionform__item--hidden {
      display: none; }
    @media screen and (max-width: 800px) {
      .actionform__item {
        width: 100%;
        max-width: none;
        margin-bottom: 0;
        padding: 3px; } }
  .actionform__input {
    width: 100%;
    font-family: 'futura';
    font-size: 20px;
    line-height: 45px;
    padding: 0 15px;
    color: #212121;
    box-sizing: border-box;
    text-align: center;
    background: #ffffff; }
  .actionform__btn {
    width: 100%;
    min-width: 100%;
    background: #ffbf00;
    text-transform: uppercase;
    color: #212121;
    font-weight: 600;
    padding: 0 20px;
    line-height: 45px;
    display: block;
    text-decoration: none;
    font-size: 16px;
    white-space: nowrap;
    box-sizing: border-box;
    cursor: pointer; }
    .actionform__btn:hover {
      background: #ed9121;
      color: #212121; }
  .actionform__formtitle {
    font-size: 22px;
    color: #ffffff;
    margin-bottom: 12px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 2px; }
    @media screen and (max-width: 800px) {
      .actionform__formtitle {
        font-size: 18px; } }
  .actionform__aferta {
    font-size: 16px;
    color: #ffffff;
    margin-top: 3px; }
    @media screen and (max-width: 800px) {
      .actionform__aferta {
        margin-top: 15px;
        font-size: 14px; } }
