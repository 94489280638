.webirus {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  color: #cccccc;
  font-size: 15px; }
  .webirus__link {
    color: #ffffff;
    text-decoration: none; }
    .webirus__link:hover {
      text-decoration: underline; }
    @media screen and (max-width: 600px) {
      .webirus__link {
        padding-left: 3px; } }
  @media screen and (max-width: 600px) {
    .webirus {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 14px; } }
