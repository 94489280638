.topbutton {
  background: url("../img/icon_topbutton@2x.svg") 50% 97%/40px 40px no-repeat rgba(0, 0, 0, 0.2);
  min-width: 100px;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
  opacity: .5;
  z-index: 1004;
  display: none; }
  @media screen and (max-width: 1000px) {
    .topbutton {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      left: 10px;
      bottom: 10px;
      background: url("../img/icon_topbutton@2x.svg") center center/20px 20px no-repeat #a65e2e;
      top: auto;
      min-width: 50px; } }
