.copyright {
  font-size: 15px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  @media screen and (max-width: 600px) {
    .copyright {
      text-align: center;
      font-size: 14px; } }
