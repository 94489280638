.phones {
  background: url("../img/icon_phones@2x.svg") 0 10px/30px 30px no-repeat;
  min-height: 30px;
  padding-left: 45px; }
  .phones__first {
    padding: 5px 0;
    line-height: 20px;
    font-size: 16px;
    color: #666666; }
    .phones__first strong {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      display: block;
      color: #212121; }
  .phones__link {
    display: block;
    color: #212121;
    text-decoration: none; }
