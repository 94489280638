@media screen and (max-width: 1000px) {
  .search {
    display: none; } }

.search__form {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row; }

.search__input--search {
  background: #ffffff;
  padding: 0 10px;
  line-height: 30px;
  display: block;
  box-sizing: border-box;
  width: 100px;
  font-size: 12px; }

.search__input--submit {
  background: #a65e2e;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  padding: 0 10px;
  line-height: 30px;
  display: block;
  text-decoration: none;
  font-size: 12px;
  white-space: nowrap;
  cursor: pointer; }
  .search__input--submit:hover {
    background: #663300;
    color: #ffffff; }

.ya-site-form__input-text {
  background: #ffffff !important;
  padding: 0 10px !important;
  line-height: 30px !important;
  display: block !important;
  box-sizing: border-box !important;
  width: 100px !important;
  font-size: 12px !important; }

.ya-site-form__submit {
  margin: 0 !important; }
  .ya-site-form__submit:hover {
    background: #663300 !important;
    color: #ffffff !important; }

#ya-site-results .b-head.b-head_type_search-simple {
  display: none !important; }

.ya-site-form__form,
.ya-site-form__wrap,
#ya-site-form0 {
  width: 100% !important;
  display: -webkit-flex !important;
  display: -ms-flex !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
  align-content: flex-end !important;
  justify-content: flex-end !important; }

.ya-site-form__wrap {
  margin: 0 10px !important; }

.ya-site-form__search-wrap {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  align-content: flex-end;
  justify-content: flex-end; }

.ya-site-form__search-input-layout tr {
  width: 150px !important;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center; }
