.navicon {
  display: none;
  cursor: pointer;
  line-height: 40px;
  font-size: 25px;
  width: 100%; }
  @media screen and (max-width: 800px) {
    .navicon {
      display: block;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      align-content: center; } }
  .navicon__text {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    padding-left: 10px; }
