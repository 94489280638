.catalog {
  width: 100%; }
  .catalog__title {
    text-align: center;
    font-weight: bold;
    font-size: 34px;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 10px;
    color: #663300; }
    @media screen and (max-width: 1000px) {
      .catalog__title {
        font-size: 24px; } }
    @media screen and (max-width: 800px) {
      .catalog__title {
        font-size: 20px; } }
  .catalog-child {
    width: 100%;
    padding-top: 20px;
    padding-bottom: 15px; }
    .catalog-child__title {
      text-align: left;
      font-weight: 600;
      color: #212121;
      text-transform: uppercase;
      margin-bottom: 15px;
      font-size: 20px; }
      @media screen and (max-width: 600px) {
        .catalog-child__title {
          margin-top: 10px;
          text-align: center; } }
    .catalog-child__list {
      width: 100%;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0 !important;
      margin: 0 !important;
      background: none !important;
      padding: 0 !important;
      padding-bottom: 0px !important; }
    .catalog-child__item {
      padding: 0 !important;
      margin: 0 !important;
      background: none !important;
      width: calc(50% - 20px) !important;
      box-sizing: border-box;
      background: #ffffff !important;
      margin: 0 10px !important;
      margin-bottom: 20px !important;
      box-sizing: border-box;
      padding: 15px 20px !important;
      border: 2px solid #ffbf00; }
      @media screen and (max-width: 1000px) {
        .catalog-child__item {
          width: 100% !important;
          margin: 0 !important;
          margin-bottom: 10px !important; } }
      @media screen and (max-width: 600px) {
        .catalog-child__item {
          padding: 5px 10px !important; } }
    .catalog-child__link {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 5px; }
      @media screen and (max-width: 600px) {
        .catalog-child__link {
          flex-direction: column; } }
    .catalog-child__imgcase {
      width: 150px;
      margin-left: 15px;
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .catalog-child__imgcase img {
        width: 100%;
        display: block; }
      @media screen and (max-width: 600px) {
        .catalog-child__imgcase {
          width: 100%;
          padding: 10px 0;
          margin: 0; } }
    .catalog-child__btn {
      background: #a65e2e;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: 600;
      padding: 0 20px;
      text-align: center;
      line-height: 40px;
      display: block;
      text-decoration: none;
      font-size: 16px;
      white-space: nowrap;
      margin-top: 10px; }
      .catalog-child__btn:hover {
        background: #663300;
        color: #ffffff; }
      @media screen and (max-width: 600px) {
        .catalog-child__btn {
          margin-top: 10px; } }
    .catalog-child__details {
      width: calc(100% - 150px);
      box-sizing: border-box;
      text-align: left;
      font-size: 16px; }
      @media screen and (max-width: 600px) {
        .catalog-child__details {
          width: 100%; } }
      .catalog-child__details table {
        width: 100%;
        max-width: 100% !important;
        display: -webkit-flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        box-sizing: border-box; }
        .catalog-child__details table tbody {
          width: 100%;
          display: -webkit-flex;
          display: -ms-flex;
          display: flex;
          flex-direction: column;
          box-sizing: border-box; }
        .catalog-child__details table tr {
          width: 100%;
          display: -webkit-flex;
          box-sizing: border-box;
          display: -ms-flex;
          display: flex;
          flex-direction: row;
          align-items: center;
          align-content: center;
          justify-content: center; }
        .catalog-child__details table tr:nth-child(odd) {
          background: #e2e2e2; }
        .catalog-child__details table tr:nth-child(even) {
          background: #ffffff; }
        .catalog-child__details table td {
          width: 50%;
          padding: 5px 10px;
          box-sizing: border-box;
          font-size: 15px;
          line-height: 15px;
          display: block; }
          @media screen and (max-width: 600px) {
            .catalog-child__details table td {
              font-size: 15px;
              line-height: 15px;
              padding: 3px 5px; } }
          .catalog-child__details table td:nth-child(1) {
            text-align: left; }
          .catalog-child__details table td:nth-child(2) {
            text-align: right;
            font-weight: 600; }
        .catalog-child__details table td[colspan="2"] {
          width: 100%;
          box-sizing: border-box; }
      .catalog-child__details table + p {
        margin-top: 10px; }
