.navigate__list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 3px 0;
  user-select: none; }
  @media screen and (max-width: 800px) {
    .navigate__list {
      display: none; } }
  .navigate__list > .navigate__item {
    padding: 0 2px; }
    .navigate__list > .navigate__item > a {
      line-height: 34px;
      display: block;
      padding: 0 15px;
      text-decoration: none;
      color: #212121;
      font-size: 14px;
      box-sizing: border-box;
      text-transform: uppercase;
      position: relative; }
      .navigate__list > .navigate__item > a:hover {
        background: #a65e2e;
        color: #ffffff; }
    .navigate__list > .navigate__item--active > a {
      background: #a65e2e;
      color: #ffffff; }
  .navigate__list > .navigate__item--parent {
    position: relative; }
    .navigate__list > .navigate__item--parent > a {
      padding-right: 40px; }
    .navigate__list > .navigate__item--parent:after {
      content: '';
      position: absolute;
      background: url("../img/icon_navigate_parent@2x.svg") 50% 50%/11px 11px no-repeat;
      right: 20px;
      height: 11px;
      width: 11px;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      filter: invert(100); }
    .navigate__list > .navigate__item--parent:hover:after {
      filter: invert(100%) grayscale(100%); }
  .navigate__list > .navigate__item--parent:hover {
    background: #ed9121; }
    .navigate__list > .navigate__item--parent:hover > a {
      color: #ffffff; }
    .navigate__list > .navigate__item--parent:hover > .navigate__nav-child {
      display: block; }

.navigate__nav-child {
  min-width: 100%;
  position: absolute;
  z-index: 50;
  display: none;
  background: #ed9121;
  box-sizing: border-box; }
  .navigate__nav-child > .navigate__item {
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    box-sizing: border-box; }
    .navigate__nav-child > .navigate__item > a {
      width: 100%;
      line-height: 40px;
      display: block;
      padding: 0 20px;
      text-decoration: none;
      color: #ffffff;
      font-size: 16px;
      box-sizing: border-box; }
      .navigate__nav-child > .navigate__item > a:hover {
        background: #a65e2e;
        color: #ffdab9; }
