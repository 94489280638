.productmenu__title {
  background: #ffbf00;
  padding: 15px 20px;
  line-height: 25px;
  font-size: 20px;
  font-weight: 600;
  text-align: center; }

.productmenu__list {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column; }

.productmenu__item {
  border-top: 1px dotted #999999; }
  .productmenu__item > a {
    display: block;
    padding: 8px 20px;
    line-height: 25px;
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
    background: #ed9121; }
    .productmenu__item > a:hover {
      background: #a65e2e;
      color: #ffdab9; }

.productmenu__nav-child .productmenu__item > a {
  font-weight: normal;
  line-height: 20px;
  background: transparent;
  font-size: 16px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #212121;
  background: #ffffff; }
  .productmenu__nav-child .productmenu__item > a:hover {
    background: #ffdab9;
    color: #434343; }
